import { useState, useEffect } from "react";
const customStyle = {
    backgroundImage: "url('https://avidnutrilabs.com/images/gallery/Fitletic-Gallery-photo-11.jpg')",
    backgroundRepeat: "none",
    backgroundSize: "cover",
    color: "orange",
    fontSize: "2.5em",
    textShadow: "2px 2px grey"
}
const App = () => {
    let [clock, setClock] = useState(3);
    useEffect(() => {
        if (clock === 0) {
            window.location = 'https://avidnutrilabs.com/fitletic.html'
            return;
        }
        const intervalId = setInterval(() => {
            setClock(clock - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [clock]);
    return (
        <div class="jumbotron d-flex align-items-center min-vh-100 bg-image"
            style={ customStyle }>
            <div class="container text-center">
                { `Redirecting to Avidnutrilabs in ${clock} seconds..`}.
            </div>
        </div>
    );
}

export default App;